import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "./ErrorHandling";
import Cookies from "js-cookie";

const ChatAPI = {
  createChat: async (data) => {
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json", accesstoken: Cookies.get("authToken") },
        body: JSON.stringify(data)
      };

      const result = await fetch(apiUrl.chat, options);
      return result;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  newConversation: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.newConversation, "post", {}, {});
      return response?.data;
    } catch (err) {
      console.log("err", err);
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  sendMessage: async (data) => {
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json", accesstoken: Cookies.get("authToken") },
        body: JSON.stringify(data)
      };

      const result = await fetch(apiUrl.message, options);
      return result;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default ChatAPI;
