import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import propTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

const Citation = (props) => {
  Citation.propTypes = {
    citations: propTypes.any,
    index: propTypes.number,
    content: propTypes.string,
    activeIndex: propTypes.number,
    setActiveIndex: propTypes.func
  };

  const [numbers, setNumbers] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const regex = /\[(\d+)\]/g;
    const matches = [...props.content.matchAll(regex)];
    const uniqueNumbers = new Set();

    matches.forEach((match) => uniqueNumbers.add(Number(match[1])));

    const numbersArray = Array.from(uniqueNumbers);

    setNumbers(numbersArray);
    setIsActive(props.activeIndex === props.index);
  }, [props.activeIndex, props.index]);

  const handleAccordionToggle = () => {
    props.setActiveIndex(isActive ? null : props.index);
  };

  return (
    <Accordion activeKey={isActive ? "0" : null} onSelect={handleAccordionToggle}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{numbers.length} Sources</Accordion.Header>
        <Accordion.Body>
          <ListGroup as="ol" numbered>
            {props.citations?.flatMap((citation, i) =>
              numbers.map((number) => {
                if (number === i + 1) {
                  return (
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-start"
                      key={`${props.index}_${i}_${number}`}
                    >
                      <div className="ms-2 me-auto">
                        {number + "."}{" "}
                        <a
                          className="info-card-link"
                          href={citation?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {citation.title}
                        </a>
                      </div>
                      <Badge bg="light" pill></Badge>
                    </ListGroup.Item>
                  );
                }
              })
            )}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Citation;
